<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        readonly
        background-color="#fff"
        dense
        flat
        solo
        hide-details
        v-bind="attrs"
        v-on="on"
      >
        <span slot="prepend" class="evo-date-label evo-text-11 mt-1">
          {{ labelText }}
        </span>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :allowed-dates="allowedDates"
      no-title
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { datePickerMixin } from '@/mixins/components/date-picker.js';
export default {
  name: "EvoDatePicker",
  mixins: [datePickerMixin]
};
</script>

<style lang="scss" scoped>
.evo-date-label {
  color: #1e2022;
  letter-spacing: 1px;
}
</style>
