<template>
  <div
    class="ellipse-wrapper"
    :class="{
      'light-yellow': background === 'lightYellow',
      'light-blue': background === 'lightBlue',
      yellow: background === 'yellow',
      blue: background === 'blue',
      green: background === 'green',
    }"
    :style="{
      filter: filter,
      transform: transform,
      'border-radius': borderRadius,
    }"
  ></div>
</template>

<script>
import { ellipseMixin } from '@/mixins/components/ellipse.js';
export default {
  name: "EvoMEllipse",
  mixins: [ellipseMixin]
};
</script>

<style lang="scss" scoped>
.ellipse-wrapper {
  width: 243px;
  height: 114px;

  &.light-yellow {
    opacity: 0.25;
    background: linear-gradient(123deg, #ffcf54 0%, #ff6d44 100%);
  }

  &.yellow {
    opacity: 0.45;
    background: linear-gradient(123deg, #ffd885 0%, #ff7f00 100%);
  }

  &.light-blue {
    opacity: 0.42;
    background: linear-gradient(
      230deg,
      #adc0ff 0%,
      rgba(105, 254, 255, 0.62) 100%
    );
  }

  &.blue {
    opacity: 0.9;
    background: linear-gradient(
      230deg,
      #adc0ff 0%,
      rgba(105, 254, 255, 0.62) 100%
    );
  }

  &.green {
    opacity: 0.9;
    background: linear-gradient(230deg, #bbf9e9 0%, #a9f8e5 100%);
  }
}
</style>
