<template>
  <div class="evo-currency-pane">
    <v-select
      class="evo-select-currency"
      v-model="currency"
      :items="currencies"
      item-text="currencyCode"
      item-vaue="currencyCode"
      append-icon="$vuetify.icons.mdiChevronDown"
      single-line
      @change="onCurrencyChange"
    >
      <template v-slot:selection="{ item }">
        <div class="px-2">
          <span class="fi mr-1" :class="'fi-' + item.countryCodeAlpha2"></span>
          <span>{{ item.currencyCode }}</span>
        </div>
      </template>
      <template v-slot:item="{ item }">
        <div>
          <span class="fi mr-1" :class="'fi-' + item.countryCodeAlpha2"></span>
          <span>{{ item.currencyCode }}</span>
        </div>
      </template>
    </v-select>
    <currency-input
      :value="amount"
      :options="currencyOptions"
      :readonly="disabled"
      @input="onAmountChange"
    />

    <v-overlay :value="overlay" opacity="0">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import CurrencyInput from "../CurrencyInput.vue";

import { currencyPaneMixin } from '@/mixins/exchange.js';
export default {
  name: "EvoCurrencyPane",
  components: { CurrencyInput },
  mixins: [currencyPaneMixin]
};
</script>

<style lang="scss" scoped>
::v-deep() {
  .v-text-field:not(.v-input--has-state):hover
    > .v-input__control
    > .v-input__slot:before {
    border: none !important;
    display: none !important;
  }

  .v-text-field:not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot:before {
    border: none !important;
    display: none !important;
  }

  .v-text-field > .v-input__control > .v-input__slot:before,
  .v-text-field > .v-input__control > .v-input__slot:after {
    border: none !important;
    display: none !important;
  }
}

.evo-currency-pane {
  position: relative;
  padding: 0 12px;
  margin: auto;
  background: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0px 2px 43px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  z-index: 1;
}
</style>
