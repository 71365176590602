export const ellipseMixin = {
  props: {
    background: {
      type: String,
      default: 'lightYellow'
    },
    width: {
      type: String,
      default: '431px'
    },
    height: {
      type: String,
      default: '202px'
    },
    filter: {
      type: String,
      default: 'blur(28px)'
    },
    transform: {
      type: String,
      default: 'rotate(25deg)'
    },
    borderRadius: {
      type: String,
      default: '50%'
    }
  },
  mounted() {
    this.setSize();
  },
  methods: {
    setSize() {
      const el = this.$el;
      const width = this.width;
      const height = this.height;
      el.style.width = width;
      el.style.height = height;
    }
  }
};
