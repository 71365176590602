<template>
  <div class="section-2">
    <div class="evo-mb-23 evo-text-23">{{ fxRateText }}</div>
    <EvoSwitcher class="evo-mb-28" leftText="7 days" rightText="30 days" v-model="switcher" />
    <v-chart
      v-if="data && data.length > 0"
      class="evo-chart"
      :class="{
        'evo-chart-mobile': $vuetify.breakpoint.xsOnly
      }"
      :option="option"
    />

    <v-overlay :value="overlay" opacity="0">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import VChart, { THEME_KEY } from 'vue-echarts';
import EvoSwitcher from '@/components/Switcher.vue';

import { currencyChartsMixin } from '@/mixins/exchange.js';
export default {
  name: 'EvoCurrencyCharts',
  components: { EvoSwitcher, VChart },
  provide: {
    [THEME_KEY]: 'light'
  },
  mixins: [currencyChartsMixin]
};
</script>

<style lang="scss" scoped>
.section-2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .evo-chart {
    height: 300px;
  }
  .evo-chart-mobile {
    height: 600px;
  }
}
</style>
