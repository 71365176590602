<template>
  <div class="section-1">
    <v-row class="evo-mobile mx-auto" no-gutters>
      <v-col cols="12">
        <div class="evo-title evo-text-71 text-center">
          {{ $t('currency_exchange_calculator.section_1_title') }}
        </div>
        <div class="evo-subtitle evo-text-29 text-center evo-opensans-regular">
          {{ $t('currency_exchange_calculator.section_1_subtitle') }}
        </div>
      </v-col>
    </v-row>

    <v-row class="evo-mobile mx-auto" no-gutters>
      <v-col cols="12" class="evo-box-wrapper">
        <div ref="sourceBox" class="evo-source">
          <EvoCurrencyPane v-model="sourceValue" />
        </div>
        <v-btn icon large color="#2161ff" class="evo-swap-icon-wrapper" @click="onSwap">
          <img class="evo-swap-icon" src="@/assets/fxrate/exchange.png" />
        </v-btn>
        <div ref="destBox" class="evo-dest">
          <EvoCurrencyPane v-model="destValue" />
        </div>
        <div class="evo-params-wrapper">
          <div class="evo-markup">
            <currency-input
              class="evo-markup-input"
              v-model="markupValue"
              :options="markupOptions"
              :prepend="$t('currency_exchange_calculator.mark_up')"
              append="%"
            />
          </div>
          <div class="evo-date">
            <EvoDatePicker v-model="date" :allowedDates="disableDatesBefore2022" />
          </div>
        </div>
        <div class="evo-rate-wrapper">
          <span class="evo-rate evo-text-30 evo-opensans-regular">{{ fxRateText }}</span>
        </div>
      </v-col>
    </v-row>

    <v-row class="evo-mobile mx-auto" no-gutters>
      <v-col cols="12">
        <EvoCurrencyCharts />
        <div class="evo-text-26 text-center evo-opensans-regular">
          {{ $t('currency_exchange_calculator.section_1_tips') }}
        </div>
      </v-col>
    </v-row>

    <EvoEllipse class="evo-ellipse" background="lightYellow" transform="rotate(0deg)" filter="blur(25px)" width="150px" height="150px" />

    <v-overlay :value="overlay" opacity="0">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import EvoEllipse from '@/mobile/components/Ellipse.vue';
import EvoDatePicker from '@/mobile/components/DatePicker.vue';
import CurrencyInput from '@/mobile/components/CurrencyInput.vue';
import EvoCurrencyPane from './CurrencyPane.vue';
import EvoCurrencyCharts from './CurrencyCharts.vue';

import { exchangeSections1Mixin } from '@/mixins/exchange.js';
export default {
  name: 'EvoMCurrencyExchangeCalculatorOne',
  mixins: [exchangeSections1Mixin],
  components: {
    EvoEllipse,
    EvoCurrencyPane,
    EvoDatePicker,
    EvoCurrencyCharts,
    CurrencyInput
  }
};
</script>

<style lang="scss" scoped>
::v-deep() {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .theme--light.v-input input,
  .theme--light.v-input textarea {
    font-weight: 600;
    color: #77838f;
    letter-spacing: 1px;
    text-align: end;
    font-size: 14px;
    line-height: 24px;
  }

  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 !important;
  }
}

::v-deep(.evo-markup-input) {
  .evo-currency-label {
    white-space: nowrap;
    color: #1e2022;
    letter-spacing: 2px;
  }

  .evo-currency-append {
    font-weight: 600;
    color: #77838f;
    letter-spacing: 2px;
  }

  .v-input__control input {
    color: #77838f !important;
  }
}

.section-1 {
  position: relative;
  color: $title_color;
  background: $header_bg_color;
  padding: 120px 0 60px;
  overflow: hidden;

  .evo-title {
    margin-bottom: 30px;
    background: linear-gradient(90deg, #58e2cb 0%, #2161ff 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .evo-subtitle {
    margin-bottom: 62px;
    line-height: 54px;
  }

  .evo-box-wrapper {
    position: relative;
    .evo-source,
    .evo-dest {
      margin: auto;
      box-shadow: 0px 4px 85px 0px rgba(0, 0, 0, 0.04);
      z-index: 1;
    }
  }

  .evo-swap-icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: -20px auto;
    z-index: 2;
    .evo-swap-icon {
      width: 82px;
    }
  }

  .evo-params-wrapper {
    display: flex;
    margin-top: 52px;
    .evo-markup,
    .evo-date {
      position: relative;
      padding: 8px 24px;
      border-radius: 8px;
      background: #fff;
      flex: 1;
    }

    .evo-markup {
      margin-right: 32px;
    }
  }

  .evo-rate-wrapper {
    display: flex;
    justify-content: center;
    margin: 58px 0 156px;
    .evo-rate {
      padding: 18px 54px;
      color: #1677ff;
      background: #d0e4ff;
      border-radius: 22px;
      line-height: 42px;
    }
  }

  .evo-ellipse {
    position: absolute;
    right: -100px;
    top: 80px;
  }
}
</style>
