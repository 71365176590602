import Vue from "vue";
const state = Vue.observable({
  ccy1: "USD",
  ccy2: "SGD",
  currencies: [],
});

// Getters
export const getCcy1 = () => state.ccy1;
export const getCcy2 = () => state.ccy2;
export const getCurrencies = () => state.currencies;
export const getCcyPair = () => `${state.ccy1}/${state.ccy2}`;

// Actions
export const setCcy1 = (ccy1) => {
  state.ccy1 = ccy1;
};
export const setCcy2 = (ccy2) => {
  state.ccy2 = ccy2;
};
export const setCurrencies = (currencies) => {
  state.currencies = currencies;
};
export const setCcyPair = (ccy1, ccy2) => {
  state.ccy1 = ccy1;
  state.ccy2 = ccy2;
};
