import { YYYY_MM_DD_TODAY } from '@/helpers/Utils.js';
import { setCcyPair } from '@/store.js';
import { queryFxRate } from '@/helpers/Apis.js';
import * as dayjs from 'dayjs';

import { setCurrencies, getCurrencies } from '@/store.js';
import { getEvonetCurrencies } from '@/helpers/Apis.js';
import { debounce } from '@/helpers/Utils.js';

import { getCcy1, getCcy2, getCcyPair } from "@/store.js";
import { getFxRateHistory } from "@/helpers/Apis.js";
import { TODAY, LAST_7_DAYS, LAST_30_DAYS } from "@/helpers/Utils.js";

import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from 'echarts/components';
use([CanvasRenderer, LineChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);

export const exchangeSections1Mixin = {
  data: () => ({
    overlay: false,
    sourceValue: { currency: 'USD', amount: 1, precision: 2, disabled: false },
    destValue: { currency: 'SGD', amount: 0, precision: 2, disabled: true },
    markupValue: 0.0,
    date: '',
    params: {
      ccy1: 'USD',
      ccy2: 'SGD',
      date: YYYY_MM_DD_TODAY,
      time: '10:00'
    },
    res: {},
    markupOptions: {
      currency: 'USD',
      currencyDisplay: 'hidden',
      precision: 1,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      autoSign: true,
      useGrouping: true,
      accountingSign: false
    }
  }),
  mounted() {
    this.calcFxRate();
  },
  methods: {
    disableDatesBefore2022(val) {
      return dayjs(val).unix() - dayjs('2021-12-31').unix() > 0;
    },
    swapElement(target, swapHeight, duration) {
      requestAnimationFrame(() => {
        target.style.transform = `translateY(${swapHeight}px)`;
        target.style.transition = `transform ${duration}ms ease`;
      });
    },
    getSourceDestTop() {
      const sourceBox = this.$refs.sourceBox;
      const destBox = this.$refs.destBox;
      let sourceTop = sourceBox.getBoundingClientRect().top;
      let destTop = destBox.getBoundingClientRect().top;
      /**
       * 初始化状态：source就是原来的source，dest就是原来的dest，没有调换位置
       */
      const isinitState = sourceTop < destTop;
      const diffY = Math.abs(sourceTop - destTop);
      return { isinitState, diffY, sourceBox, destBox };
    },
    /**
     * 切换source和dest，并计算汇率
     * 1. source amount可编辑，dest amount不可编辑；
     * 2. source amount始终置为1
     */
    onSwap() {
      const { isinitState, diffY, sourceBox, destBox } = this.getSourceDestTop();
      const duration = 220;
      if (isinitState) {
        this.swapElement(sourceBox, diffY, duration);
        this.swapElement(destBox, -diffY, duration);
        this.params.ccy1 = this.destValue.currency;
        this.params.ccy2 = this.sourceValue.currency;
        this.destValue.amount = 1;
      } else {
        this.swapElement(destBox, 0, duration);
        this.swapElement(sourceBox, -0, duration);
        this.params.ccy1 = this.sourceValue.currency;
        this.params.ccy2 = this.destValue.currency;
        this.sourceValue.amount = 1;
      }

      this.sourceValue.disabled = isinitState;
      this.destValue.disabled = !isinitState;
      setCcyPair(this.params.ccy1, this.params.ccy2);
      setTimeout(() => {
        this.calcFxRate();
      }, duration + 80);
    },
    calcFxRate() {
      this.overlay = true;
      this.$evoData.ccy1 = this.params.ccy1;
      this.$evoData.ccy2 = this.params.ccy2;
      queryFxRate(this.params).then(
        res => {
          this.overlay = false;
          this.res = res || {};
          const { isinitState } = this.getSourceDestTop();
          if (isinitState) {
            this.destValue.amount = this.calculator(this.sourceValue.amount);
          } else {
            this.sourceValue.amount = this.calculator(this.destValue.amount);
          }
        },
        err => {
          this.overlay = false;
          throw err;
        }
      );
    },
    /**
     * Destination Currency Amount = Source Currency Amount * Ask of ccyPair * (1+Markup)
     * 
     * {      
     *    "ccyPair": "USD/SGD",
          "ccy1": "USD",
          "ccy2": "SGD",
          "bid": 1.3621047,
          "ask": 1.3702696,
          "date": "2022-04-21",
          "time": "10:00"
     * }
     */
    calculator(amount) {
      let resultAmount = 0;
      if (amount === 0) {
        return 0;
      }

      const { isinitState } = this.getSourceDestTop();
      if (isinitState) {
        resultAmount = amount * this.res.ask * (1 + this.markupValue / 100);
        resultAmount = +resultAmount.toFixed(this.destValue.precision);
      } else {
        resultAmount = amount * this.res.ask * (1 + this.markupValue / 100);
        resultAmount = +resultAmount.toFixed(this.sourceValue.precision);
      }

      return resultAmount;
    },
    resetAmount() {
      this.sourceValue.amount = 0;
      this.destValue.amount = 0;
    },
    validate(newValue, oldValue) {
      if (newValue.currency === oldValue.currency && newValue.amount === oldValue.amount) {
        return false;
      }

      if (this.sourceValue.currency === this.destValue.currency) {
        this.sourceValue.amount = 1;
        this.destValue.amount = 1;
        this.params = {
          ...this.params,
          ccy1: this.sourceValue.currency,
          ccy2: this.destValue.currency
        };
        return false;
      }

      return true;
    },
    /**
     * 处理source和dest的amount变化
     * 这里的source为UI位置靠上的source，dest为UI位置靠下的dest
     * 1. source或者dest的currency改变会触发source.amount=1
     */
    handleSourceDestChange(sourceValue, destValue, isCurrencyChange) {
      if (isCurrencyChange) {
        sourceValue.amount = 1;
      }

      this.params = {
        ...this.params,
        ccy1: sourceValue.currency,
        ccy2: destValue.currency
      };
    }
  },
  computed: {
    fxRateText() {
      if (this.params.ccy1 === this.params.ccy2) {
        return `1 ${this.params.ccy1} = 1 ${this.params.ccy2}`;
      }

      return `1 ${this.params.ccy1} = ${this.res.ask} ${this.params.ccy2}`;
    }
  },
  watch: {
    sourceValue: {
      handler(newValue, oldValue) {
        if (!this.validate(newValue, oldValue)) {
          return;
        }

        const { isinitState } = this.getSourceDestTop();
        if (isinitState) {
          this.handleSourceDestChange(newValue, this.destValue, newValue.currency !== oldValue.currency);
        } else {
          this.handleSourceDestChange(this.destValue, newValue, newValue.currency !== oldValue.currency);
        }

        setCcyPair(this.params.ccy1, this.params.ccy2);

        this.calcFxRate();
      },
      deep: true
    },
    destValue: {
      handler(newValue, oldValue) {
        if (!this.validate(newValue, oldValue)) {
          return;
        }

        const { isinitState } = this.getSourceDestTop();
        if (isinitState) {
          this.handleSourceDestChange(this.sourceValue, newValue, newValue.currency !== oldValue.currency);
        } else {
          this.handleSourceDestChange(newValue, this.sourceValue, newValue.currency !== oldValue.currency);
        }

        setCcyPair(this.params.ccy1, this.params.ccy2);
        this.calcFxRate();
      },
      deep: true
    },
    markupValue: {
      handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }

        this.markupValue = newValue || 0.0;

        const { isinitState } = this.getSourceDestTop();
        if (isinitState) {
          this.destValue.amount = this.calculator(this.sourceValue.amount);
        } else {
          this.sourceValue.amount = this.calculator(this.destValue.amount);
        }
      }
    },
    date: {
      handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }

        this.params = {
          ...this.params,
          date: newValue
        };

        this.calcFxRate();
      }
    }
  }
};

export const currencyPaneMixin = {
  props: {
    value: Object,
    options: Object
  },
  data: () => ({
    overlay: false,
    currency: 'USD',
    currencies: [],
    amount: null,
    disabled: false,
    currencyOptions: {
      currency: 'USD',
      precision: undefined,
      currencyDisplay: 'narrowSymbol',
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      autoSign: true,
      useGrouping: true,
      accountingSign: false
    }
  }),
  created() {
    this.onAmountChange = debounce(amount => {
      this.amount = amount;
      this.emitValue(amount, this.currency, this.getPrecision(this.currency));
    }, 300);
  },
  mounted() {
    this.initPropsValue();
    const storeGetCurrencies = getCurrencies();
    if (storeGetCurrencies && storeGetCurrencies.length > 0) {
      this.currencies = storeGetCurrencies;
      this.emitValue(this.amount, this.currency, this.getPrecision(this.currency));
    } else {
      this.fetchCurrencies();
    }
  },
  methods: {
    initPropsValue() {
      if (this.value) {
        this.currency = this.value.currency || 'USD';
        this.amount = this.value.amount || 0;
        this.disabled = this.value.disabled || false;
      }

      this.currencyOptions = this.options || this.currencyOptions;
    },
    fetchCurrencies() {
      this.overlay = true;
      getEvonetCurrencies().then(
        res => {
          this.overlay = false;
          this.buildCurrencies(res);
        },
        err => {
          this.overlay = false;
          throw err;
        }
      );
    },
    buildCurrencies(data) {
      let currencies = [];
      Object.entries(data).forEach(([key, value]) => {
        let countryCodeAlpha2 = `${value.countryCodeAlpha2 || key}`.toLocaleLowerCase().slice(0, 2);

        currencies.push({
          name: value.name,
          currencyCode: key,
          countryCodeAlpha2: countryCodeAlpha2,
          precision: value.decimal || undefined
        });
      });

      this.currencies = currencies;
      const storeGetCurrencies = getCurrencies();
      if (!storeGetCurrencies || storeGetCurrencies.length === 0) {
        setCurrencies(currencies);
      }

      // 这里的emit主要因为需要等currencies拿到以后计算precision
      this.emitValue(this.amount, this.currency, this.getPrecision(this.currency));
    },
    onCurrencyChange(currency) {
      this.emitValue(this.amount, currency, this.getPrecision(currency));
    },
    emitValue(amount, currency, precision) {
      this.$emit('input', {
        name: this.getCurrencyName(currency),
        currency: currency,
        amount: amount,
        precision: precision,
        disabled: this.disabled
      });
    },
    getPrecision(currency) {
      const item = this.currencies.find(item => item.currencyCode === currency);
      return (item && item.precision) || undefined;
    },
    getCurrencyName(currency) {
      const item = this.currencies.find(item => item.currencyCode === currency);
      return (item && item.name) || undefined;
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.currency = value.currency || 'USD';
          this.amount = value.amount || 0;
          this.currencyOptions.currency = value.currency || 'USD';
          this.disabled = value.disabled || false;
        }
      },
      deep: true
    }
  }
};

export const currencyChartsMixin = {
  data: () => ({
    overlay: false,
    fxRateText: '',
    switcher: 'left',
    option: {},
    last7Days: {
      rateDateStart: LAST_7_DAYS,
      rateDateEnd: TODAY
    },
    last30Days: {
      rateDateStart: LAST_30_DAYS,
      rateDateEnd: TODAY
    },
    params: {},
    data: []
  }),
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.getFxRateText();
      this.fetchFxRateHistory();
    },
    getFxRateText() {
      const currencies = this.currencies;
      if (!currencies || currencies.length === 0) {
        this.fxRateText = 'unknown';
      }

      // format: name (ccy1) / name (ccy2)
      this.fxRateText = `${this.getCurrency(this.ccy1).name} (${this.ccy1}) / ${this.getCurrency(this.ccy2).name} (${this.ccy2})`;
    },
    buildParams() {
      if (this.switcher === 'left') {
        this.params = {
          ccyPair: `${this.ccy1}/${this.ccy2}`,
          fxRateOwner: 'evonet',
          rateDateStart: this.last7Days.rateDateStart,
          rateDateEnd: this.last7Days.rateDateEnd
        };
      } else {
        this.params = {
          ccyPair: `${this.ccy1}/${this.ccy2}`,
          fxRateOwner: 'evonet',
          rateDateStart: this.last30Days.rateDateStart,
          rateDateEnd: this.last30Days.rateDateEnd
        };
      }
    },
    getCurrency(currencyCode) {
      return this.currencies.find(item => item.currencyCode === currencyCode) || {};
    },
    setChartData(data) {
      let cloneData = [...data];
      const ascData = cloneData.sort((a, b) => {
        return dayjs(a.rateDate).unix() - dayjs(b.rateDate).unix();
      });

      let xData = ascData.map(item => this.getFormatDate(item.rateDate));
      const sData = ascData.map(item => item.ask.toFixed(7));
      this.option = this.getChartOption(xData, sData);
    },
    getFormatDate(date) {
      if (!date) return '-';
      const week = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
      const day = dayjs(date).day();
      const dateTime = dayjs(date).format('MM/DD');
      return `${dateTime} ${week[day]}`;
    },
    getChartOption(xAxisData, seriesData) {
      return {
        grid: {
          left: 20,
          right: 20,
          top: 12,
          bottom: 48,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: '#E4E6E8',
              opacity: 0.6
            }
          },
          axisLabel: {
            padding: [0, 16, 0, 0],
            color: '#6A74A5',
            lineHeight: 40,
            fontFamily: 'Segoe-UI-SemiBold'
          },
          axisTick: {
            show: false
          },
          data: xAxisData
        },
        yAxis: {
          show: true,
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E4E6E8',
              opacity: 0.6
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(179, 192, 231, 0.2)'
            }
          },
          // 解决y轴折线波动太小的问题
          // @ts-ignore
          min: function (value) {
            return value.min;
          },
          axisLabel: {
            color: '#6A74A5',
            lineHeight: 40,
            // fontFamily: "Segoe-UI-SemiBold",
            // eslint-disable-next-line no-unused-vars
            formatter: function (value, index) {
              return value.toFixed(7);
            }
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            data: seriesData,
            type: 'line',
            symbolSize: 18,
            showSymbol: false,
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#3F8CFF' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#fff' // 100% 处的颜色
                  }
                ]
              }
            }
          }
        ]
      };
    },
    fetchFxRateHistory() {
      this.buildParams();
      this.overlay = true;
      getFxRateHistory(this.params).then(
        res => {
          this.overlay = false;
          const data = res.data;
          this.data = data;
          this.setChartData(data);
        },
        err => {
          this.overlay = false;
          throw err;
        }
      );
    }
  },
  computed: {
    ccy1() {
      return getCcy1();
    },
    ccy2() {
      return getCcy2();
    },
    ccyPair() {
      return getCcyPair();
    },
    currencies() {
      return getCurrencies();
    }
  },
  watch: {
    currencies: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue, oldValue) {
        if (!newValue || newValue.length === 0) {
          return;
        }

        this.refreshData();
      },
      deep: true
    },
    ccyPair: {
      // eslint-disable-next-line no-unused-vars
      handler(newValue, oldValue) {
        if (!newValue || newValue.length === 0) {
          return;
        }

        this.refreshData();
      },
      deep: true
    },
    switcher() {
      this.refreshData();
    }
  }
};
