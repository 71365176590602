export const switcherMixin = {
  props: {
    value: {
      type: String,
      default: "left",
    },
    leftText: {
      type: String,
      default: "",
    },
    rightText: {
      type: String,
      default: "",
    },
  },
  data: (vm) => ({
    isLeft: vm.value === "left",
    lText: vm.leftText,
    rText: vm.rightText,
  }),
  methods: {
    onItemClick(val) {
      if (
        (val === "left" && this.isLeft) ||
        (val === "right" && !this.isLeft)
      ) {
        return;
      }

      this.isLeft = !this.isLeft;
      this.$emit("input", this.isLeft ? "left" : "right");
    },
  },
  watch: {
    value(val) {
      this.isLeft = val === "left";
    },
  },
};