<template>
  <div class="section-2">
    <div class="evo-title evo-text-46">
      {{ fxRateText }}
    </div>
    <EvoSwitcher class="evo-switcher" leftText="7 days" rightText="30 days" v-model="switcher" />
    <v-chart v-if="data && data.length > 0" class="evo-chart" :option="option" />

    <v-overlay :value="overlay" opacity="0">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>

    <EvoEllipse class="evo-ellipse" background="lightYellow" transform="rotate(0deg)" filter="blur(25px)" width="150px" height="150px" />
  </div>
</template>

<script>
import EvoEllipse from '@/mobile/components/Ellipse.vue';
import EvoSwitcher from '@/mobile/components/Switcher.vue';
import VChart, { THEME_KEY } from 'vue-echarts';

import { currencyChartsMixin } from '@/mixins/exchange.js';
export default {
  name: 'EvoMCurrencyCharts',
  components: { EvoEllipse, EvoSwitcher, VChart },
  provide: {
    [THEME_KEY]: 'light'
  },
  mixins: [currencyChartsMixin],
  methods: {
    getChartOption(xAxisData, seriesData) {
      return {
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: '#E4E6E8',
              opacity: 0.6
            }
          },
          axisLabel: {
            padding: [0, 16, 0, 0],
            color: '#6A74A5',
            lineHeight: 40,
            fontFamily: 'Segoe-UI-SemiBold'
          },
          axisTick: {
            show: false
          },
          data: xAxisData
        },
        yAxis: {
          show: true,
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E4E6E8',
              opacity: 0.6
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(179, 192, 231, 0.2)'
            }
          },
          // 解决y轴折线波动太小的问题
          // @ts-ignore
          min: function (value) {
            return value.min;
          },
          axisLabel: {
            color: '#6A74A5',
            lineHeight: 40,
            // fontFamily: "Segoe-UI-SemiBold",
            // eslint-disable-next-line no-unused-vars
            formatter: function (value, index) {
              return value.toFixed(7);
            }
          }
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [
          {
            data: seriesData,
            type: 'line',
            symbolSize: 18,
            showSymbol: false,
            smooth: true,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#3F8CFF' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#fff' // 100% 处的颜色
                  }
                ]
              }
            }
          }
        ]
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.section-2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .evo-title {
    margin-bottom: 53px;
    position: relative;
    z-index: 1;
  }
  .evo-switcher {
    margin-bottom: 65px;
  }
  .evo-chart {
    height: 600px;
    margin-bottom: 65px;
  }

  .evo-ellipse {
    position: absolute;
    top: -12vw;
    left: -12vw;
    z-index: 0;
  }
}
</style>
