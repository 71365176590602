import { fetchWrapper } from "@/helpers/FetchWrapper.js";
import { objectToQueryString } from "@/helpers/Utils.js";

const API_FXRATE_HISTORY = "/evonet/fxrate/history";
const API_CURRENCY = "/evonet/currency";
const API_CONTACT = "/evonet/contact";
const API_QUERY_FXRATE = "/evonet/query";

export function getFxRateHistory(params) {
  return fetchWrapper.get(
    `${API_FXRATE_HISTORY}?${objectToQueryString(params)}`
  );
}

export function getEvonetCurrencies() {
  return fetchWrapper.get(`${API_CURRENCY}`);
}

export function contactUs(data) {
  return fetchWrapper.post(`${API_CONTACT}`, data);
}

export function queryFxRate(data) {
  return fetchWrapper.post(`${API_QUERY_FXRATE}`, data);
}
