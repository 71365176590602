<template>
  <v-row justify="space-between" align="center">
    <v-col class="evo-currency-label evo-text-11">
      {{ prependValue ? prependValue : "Amount:" }}
    </v-col>
    <v-col>
      <v-text-field
        :key="'evo-text-field-currency'"
        class="evo-currency-input evo-text-14"
        ref="inputRef"
        v-model="modelValue"
        :disabled="disabled"
        background-color="#fff"
        dense
        flat
        solo
        hide-details
      >
        <span
          v-if="appendValue"
          slot="append"
          class="evo-currency-append evo-text-14"
        >
          {{ appendValue }}
        </span>
      </v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";
import { watch, ref } from "vue";

export default {
  name: "CurrencyInput",
  props: {
    value: Number,
    options: Object,
    readonly: Boolean,
    prepend: String,
    append: String,
  },
  setup(props) {
    const { inputRef, setValue, setOptions } = useCurrencyInput(props.options);
    const disabled = ref(props.readonly);

    watch(
      () => props.value,
      (newValue) => {
        setValue(newValue);
      }
    );
    // watch options
    watch(
      () => props.options,
      (newOptions) => {
        setOptions(newOptions);
      },
      {
        deep: true,
      }
    );
    // watch readonly
    watch(
      () => props.readonly,
      (newValue) => {
        disabled.value = newValue;
      }
    );

    return {
      inputRef,
      disabled,
      modelValue: props.value,
      prependValue: props.prepend,
      appendValue: props.append,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep() {
  .v-input__control input {
    text-align: end !important;
    font-weight: 600 !important;
    color: #2161ff !important;
    letter-spacing: 1px !important;
  }
}

.evo-currency-label {
  color: #77838f;
  letter-spacing: 1px;
}
</style>
