<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    bottom
    left
    offset-y
    max-width="290px"
    min-width="auto"
    transition="scale-transition"
    origin="right top"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-center">
        <div class="evo-date-label evo-text-26 evo-opensans-regular">{{ labelText }}</div>
        <v-text-field v-model="computedDateFormatted" readonly background-color="#fff" dense flat solo hide-details v-bind="attrs" v-on="on">
        </v-text-field>
      </div>
    </template>
    <v-date-picker v-model="date" :allowed-dates="allowedDates" no-title @input="menu = false"></v-date-picker>
  </v-menu>
</template>

<script>
import { datePickerMixin } from '@/mixins/components/date-picker.js';
export default {
  name: 'EvoMDatePicker',
  mixins: [datePickerMixin]
};
</script>

<style lang="scss" scoped>
::v-deep(.v-input__control input) {
  letter-spacing: 2px !important;
  font-size: 26px !important;
}
.evo-date-label {
  color: #1e2022;
  font-weight: bold;
  letter-spacing: 2px;
}
</style>
