<template>
  <div class="evo-switcher">
    <span
      class="evo-switcher-item"
      :class="{ 'evo-switcher-color-active': isLeft }"
      @click="onItemClick('left')"
    >
      {{ lText }}
    </span>
    <span
      class="evo-switcher-item"
      :class="{ 'evo-switcher-color-active': !isLeft }"
      @click="onItemClick('right')"
    >
      {{ rText }}
    </span>
    <span
      class="evo-switcher-item-active"
      :class="{
        'evo-switcher-item-left-active': isLeft,
        'evo-switcher-item-right-active': !isLeft,
      }"
    >
    </span>
  </div>
</template>
<script>
import { switcherMixin } from '@/mixins/components/switcher.js';
export default {
  name: "EvoSwitcher",
  mixins: [switcherMixin]
};
</script>
<style lang="scss" scoped>
.evo-switcher {
  position: relative;
  display: flex;
  width: 160px;
  border-radius: 14px;
  background: #fff;

  .evo-switcher-item {
    position: relative;
    border-radius: 14px;
    width: 50%;
    z-index: 3;
    background: transparent;
    cursor: pointer;
    font-size: 11px;
    font-weight: 600;
    color: #5e6c84;
    padding: 4px 16px;
    text-align: center;
  }

  .evo-switcher-color-active {
    color: #fff;
    transition: color 0.1s ease;
  }

  .evo-switcher-item-active {
    display: none;
    position: absolute;
    background: #2962ff;
    border-radius: 14px;
    width: 50%;
    height: 100%;
    opacity: 1;
    transition: left 0.3s ease;
    z-index: 2;
  }

  .evo-switcher-item-left-active {
    display: inline-block;
    left: 0;
    top: 0;
  }

  .evo-switcher-item-right-active {
    display: inline-block;
    top: 0;
    left: 50%;
  }
}
</style>
