<template>
  <div
    class="section-1 evo-ph-206 evo-pv-60"
    :class="{
      'evo-pv-100': $vuetify.breakpoint.xsOnly,
      'evo-ph-80': $vuetify.breakpoint.xsOnly
    }"
  >
    <v-row class="px-0" align="center" justify="center">
      <v-col cols="12" sm="12" md="12">
        <div
          class="evo-section1-title evo-text-34"
          :class="{
            'evo-px-text-32': $vuetify.breakpoint.xsOnly,
            'evo-px-section1-title-mobile': $vuetify.breakpoint.xsOnly
          }"
          data-aos="fade-up"
        >
          {{ $t('currency_exchange_calculator.section_1_title') }}
        </div>
        <div
          class="evo-section1-subtitle evo-text-16 evo-opensans-regular"
          :class="{
            'evo-px-text-16': $vuetify.breakpoint.xsOnly,
            'evo-px-section1-subtitle-mobile': $vuetify.breakpoint.xsOnly
          }"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {{ $t('currency_exchange_calculator.section_1_subtitle') }}
        </div>
      </v-col>
    </v-row>

    <v-row class="px-0 evo-mb-60" align="center" justify="center">
      <v-col class="evo-box-wrapper" cols="12" sm="12" md="8">
        <div ref="sourceBox" class="evo-source evo-px-height-108" data-aos="fade-up" data-aos-delay="150">
          <EvoCurrencyPane v-model="sourceValue" />
        </div>
        <v-btn icon large color="#2161ff" class="evo-swap-icon" @click="onSwap" data-aos="fade-up" data-aos-delay="200">
          <!-- <v-icon> mdi-swap-vertical-circle </v-icon> -->
          <v-img src="@/assets/fxrate/exchange.png" max-width="41px" contain />
        </v-btn>
        <div ref="destBox" class="evo-dest evo-px-height-108" data-aos="fade-up" data-aos-delay="250">
          <EvoCurrencyPane v-model="destValue" />
        </div>
        <div class="d-flex flex-row justify-space-between evo-params-wrapper" data-aos="fade-up" data-aos-delay="300">
          <div class="evo-markup mr-2">
            <currency-input
              class="evo-markup-input"
              v-model="markupValue"
              :options="markupOptions"
              :prepend="$t('currency_exchange_calculator.mark_up')"
              append="%"
            />
          </div>
          <div class="evo-date ml-2">
            <EvoDatePicker v-model="date" :allowedDates="disableDatesBefore2022" />
          </div>
        </div>
        <v-row class="evo-rate-wrapper" align="center" justify="center">
          <v-col cols="3" sm="3" md="3">&nbsp;</v-col>
          <v-col class="evo-rate evo-text-10" cols="6" sm="6" md="6" data-aos="fade-up" data-aos-delay="350">
            <span>{{ fxRateText }}</span>
          </v-col>
          <v-col cols="3" sm="3" md="3">&nbsp;</v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="px-0" align="center" justify="center">
      <v-col cols="12" sm="12" md="12" align-self="center">
        <EvoCurrencyCharts data-aos="fade-up" />
        <div
          class="evo-section1-tips evo-text-9 evo-opensans-regular"
          :class="{
            'evo-px-text-9': $vuetify.breakpoint.xsOnly
          }"
          data-aos="fade-up"
        >
          {{ $t('currency_exchange_calculator.section_1_tips') }}
        </div>
      </v-col>
    </v-row>

    <EvoEllipse class="evo-section1-ellipse" background="lightYellow" transform="rotate(0deg)" filter="blur(25px)" width="150px" height="150px" />

    <EvoEllipse class="evo-section1-ellipse-2" background="lightYellow" transform="rotate(0deg)" filter="blur(25px)" width="150px" height="150px" />

    <v-overlay :value="overlay" opacity="0">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import EvoEllipse from '@/components/Ellipse.vue';
import CurrencyInput from '@/components/CurrencyInput.vue';
import EvoDatePicker from '@/components/DatePicker.vue';
import EvoCurrencyCharts from './CurrencyCharts.vue';
import EvoCurrencyPane from './CurrencyPane.vue';

import { exchangeSections1Mixin } from '@/mixins/exchange.js';
export default {
  name: 'EvoCurrencyExchangeCalculatorOne',
  mixins: [exchangeSections1Mixin],
  components: {
    EvoEllipse,
    EvoCurrencyPane,
    EvoDatePicker,
    EvoCurrencyCharts,
    CurrencyInput
  }
};
</script>

<style lang="scss" scoped>
::v-deep() {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .theme--light.v-input input,
  .theme--light.v-input textarea {
    font-weight: 600;
    color: #77838f;
    letter-spacing: 1px;
    text-align: end;
    font-size: 14px;
    line-height: 24px;
  }

  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 !important;
  }
}

.section-1 {
  position: relative;
  background: #fafafa;
  padding-left: 270px;
  padding-right: 270px;

  .evo-section1-title {
    text-align: center;
    margin-bottom: 19px;
    font-weight: bold;
    color: #1f252d;
    background: linear-gradient(90deg, #58e2cb 0%, #2161ff 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .evo-px-section1-title-mobile {
    line-height: 40px;
  }

  .evo-section1-subtitle {
    text-align: center;
    line-height: 33px;
    font-weight: 400;
    color: #1f252d;
  }

  .evo-px-section1-subtitle-mobile {
    line-height: 20px;
  }

  .evo-section1-tips {
    color: #1f252d;
    line-height: 16px;
    text-align: center;
  }

  .evo-box-wrapper {
    position: relative;
    .evo-source,
    .evo-dest {
      margin: auto;
      box-shadow: 0px 2px 43px 0px rgba(0, 0, 0, 0.04);
      z-index: 1;
    }
  }

  .evo-swap-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: -10px auto;
    z-index: 2;
    & .v-icon {
      font-size: 41px;
    }
  }

  .evo-section1-ellipse {
    position: absolute;
    right: -30px;
    top: 300px;
  }

  .evo-section1-ellipse-2 {
    position: absolute;
    left: -15px;
    bottom: 200px;
  }

  .evo-params-wrapper {
    margin-top: 18px;

    .evo-markup,
    .evo-date {
      position: relative;
      padding: 8px 12px;
      border-radius: 8px;
      background: #fff;
      flex: 1;
    }
  }

  .evo-rate-wrapper {
    margin-top: 24px;
    .evo-rate {
      padding: 8px 12px;
      background: #d0e4ff;
      border-radius: 8px;
      text-align: center;
      color: #1677ff;
    }
  }
}
</style>

<style lang="scss">
.evo-markup-input {
  align-items: center !important;
  .evo-currency-label {
    white-space: nowrap;
    color: #1e2022;
    letter-spacing: 1px;
  }

  .evo-currency-append {
    font-weight: 600;
    color: #77838f;
    letter-spacing: 1px;
    line-height: 24px;
  }

  .v-input__control input {
    color: #77838f !important;
  }
}
</style>
