var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ellipse-wrapper",class:{
    'light-yellow': _vm.background === 'lightYellow',
    'light-blue': _vm.background === 'lightBlue',
    yellow: _vm.background === 'yellow',
    blue: _vm.background === 'blue',
    green: _vm.background === 'green',
  },style:({
    filter: _vm.filter,
    transform: _vm.transform,
    'border-radius': _vm.borderRadius,
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }