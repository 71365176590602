<template>
  <div>
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMCurrencyExchangeCalculatorOne />
    </template>
    <template v-else>
      <EvoCurrencyExchangeCalculatorOne />
    </template>
  </div>
</template>

<script>
import EvoCurrencyExchangeCalculatorOne from '@/components/currency_exchange_calculator_sections/Section_1.vue';
import EvoMCurrencyExchangeCalculatorOne from '@/mobile/components/currency_exchange_calculator_sections/Section_1.vue';
export default {
  name: 'EvoCurrenyExchangeCalculator',
  components: {
    EvoCurrencyExchangeCalculatorOne,
    EvoMCurrencyExchangeCalculatorOne
  }
};
</script>
