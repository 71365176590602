import { YYYY_MM_DD_TODAY } from '@/helpers/Utils.js';
export const datePickerMixin = {
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Date'
    },
    allowedDates: {
      type: Function,
      default: null
    }
  },
  data: vm => ({
    labelText: vm.label,
    date: vm.value || YYYY_MM_DD_TODAY,
    menu: false
  }),
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
      this.$emit('input', val);
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${year}-${month}-${day}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
  }
}